<template>
  <el-main>
    <p>同步百度云盘：</p>
    <p>1、点击添加百度云盘，百度云盘App扫码授权，授权成功以后，素材支持从手机端搜索预览</p>
    <p>2、可以同时授权多个百度云盘</p>
    <el-button class="addBtn" type="primary" size="small" @click="addCloudDisk()">添加百度云盘</el-button>
    <el-table :data="data_list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="baidu_name" label="百度云盘账号" align="center"></el-table-column>
      <el-table-column prop="name" label="授权时间" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template v-slot="{ row }">
          <el-button type="text" size="small" @click="updateDisk(row.id)">更新网盘</el-button>
          <el-button type="text" size="small" @click="deleteCloudDisk(row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging.vue';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      data_list: [],
      getDateformat,
      page: 1,
      rows: 10,
      total: 0,
    };
  },
  created() {
    this.getCloudDisk();
  },
  methods: {
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      this.getCloudDisk();
    },
    addCloudDisk() {
      this.$axios
        .post(this.$api.set.BaiduCloudDisk.getAuthorizeUrl)
        .then(res => {
          if (res.code === 0) {
            window.open(res.result);
            return true;
          } else {
            this.$message.error(res.msg);
            return false;
          }
        })
        .then(res => {
          if (res) {
            this.$confirm('是否完成授权?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
              .then(() => {
                this.getCloudDisk();
              })
              .catch();
          }
        });
    },
    getCloudDisk() {
      this.$axios
        .post(this.$api.set.BaiduCloudDisk.listAll, {
          page: this.page,
          rows: this.rows,
        })
        .then(res => {
          if (res.code == 0) {
            this.data_list = res.result.list;
            this.total = res.result.total;
          }
        });
    },
    deleteCloudDisk(id) {
      this.$confirm('确定删除该百度云盘?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          return this.$axios.post(this.$api.set.BaiduCloudDisk.delete, {
            id,
          });
        })
        .then(res => {
          if (res.code === 0) {
            this.getCloudDisk();
            this.$message.success('删除成功');
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    updateDisk(id) {
      this.$confirm('确定更新该百度云盘吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          return this.$axios.post(this.$api.set.BaiduCloudDisk.updateStatus, {
            id,
          });
        })
        .then(res => {
          if (res.code === 0) {
            this.$message.success('更新成功');
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  p {
    font-size: 14px;
    margin-top: 10px;
    color: #737373;
  }
  .addBtn {
    margin: 20px 0;
  }
}
</style>
